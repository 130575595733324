import React from 'react';
import history from './services/history';
import Routes from './routes';
import "./App.css"

import {Router} from 'react-router-dom';


import "./assets/css/bootstrap.min.css"
import "./assets/css/logincss.css"

import "./assets/css/agency.min.css"
import "./App.css"
export default function App() {
  
  return (
    <Router history = {history}>
    <Routes />
    </Router>
  
  );
}


