import { useEffect, useState } from 'react';
import history from "../../services/history"


export default function useHeight() {
 

  const [height, setHeight] = useState(window.innerHeight * (window.visualViewport?.scale || 1));

  useEffect(() => {
    const onResize = () => {
      setHeight(window.innerHeight * (window.visualViewport?.scale || 1));
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });
  sessionStorage.setItem("vidHeight",height.toString()+"px");
  return height + 'px';

}