import { useCallback, useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import {URL} from 'url';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

export default function useFirebaseAuth() {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const getToken = useCallback(
    async (identity: string, roomName: string) => {
      const headers = new window.Headers();
   var proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  var targetUrl = 'https://us-central1-protst.cloudfunctions.net/video/token';
  
    //  const idToken = await user!.getIdToken();
     // headers.set('Authorization', idToken);
     var ur  =proxyUrl+targetUrl;
    var url = (ur);
    
      const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
      const params =  new URLSearchParams({
            identity:identity,
            roomName:roomName
        })   
    

      return fetch(`${url}?${params}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => res.text());
    },
    [user]
  );
//   useCallback(
//     async(identity: string, roomName: string) => {
//       //console.log("data: ", (campaign.name.toString() ));
//       event.preventDefault();
//       var proxyUrl = 'https://cors-anywhere.herokuapp.com/';
//   var targetUrl = 'https://us-central1-protst.cloudfunctions.net/video/token';
//   let url = new URL(proxyUrl+targetUrl);
// url.search = new URLSearchParams({
//     identity:username,
//     roomName:roomName
// })    

//   const data = await fetch((url), {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       })
//       .then(res => res.json());
//     },
//     [roomName, user]
//   );

  useEffect(() => {
    if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    }
    firebase.auth().onAuthStateChanged(user => {
      setUser(user);
      setIsAuthReady(true);
    });
  }, []);

  const signIn = useCallback(() => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/plus.login');

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(user => {
        setUser(user.user);
      });
  }, []);

  const signOut = useCallback(() => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      });
  }, []);

  return { user, signIn, signOut, isAuthReady, getToken };
}
